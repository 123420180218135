.helpSlider {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin: 0 15px;
    cursor: pointer;
    padding: 6px;
    border-radius: 5px;
    transition: 0.4s;
}

.link:hover {
    background-color: rgba(0, 0, 0, 0.089);
}


.helpSliderBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.helpSliderContent h5 {
    margin: 10px 0;
    text-align: center;
    text-transform: capitalize;
    color: #9c1313b9;
    font-size: 1.6rem;
}

.helpSliderContent h6 {
    font-size: 14px;
}

.helpSliderBox button {
    text-transform: uppercase;
    padding: 6px 16px;
    border-radius: 50px;
    background-color: #1D9929;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 0;
    width: 120px;
    margin-top: 20px;
}

.helpSliderBox button:hover {
    background-color: #1D9929;
    box-shadow: 0 0 5px #1D9929;
}