.testimonialsContainer {
    display: flex;
    justify-content: space-between;
    padding-left: 200px;
}

.titleNavigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;
}

.heading h5 {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
}


.card {
    width: 404px;
    border-radius: 10px;
    padding-bottom: 20px;
    min-height: 615px;
}

.cardImg {
    height: 310px;
    border-radius: 50%;
}

.cardImg img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.cardContent {
    padding: 20px 14px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 50%, rgba(242, 242, 242, 0.1) 1%);
    min-height: 230px;
    color: #a09e9e;
    font-size: 16px;
}

.cardProfile {
    margin-bottom: 10px;
}

.profileTitle h5 {
    font-size: 21px;
    font-weight: 500;
    color: #f8f8f8;
    margin-bottom: 2px;
}

.profileTitle h6 {
    font-size: 14px;
    font-weight: 400;
    margin-top: -3px;
    margin-left: 2px;
    color: #f8f8f8;
}

.navigation {
    display: flex;
    gap: 20px;
}

.navigation img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

@media only screen and (max-width:1500px) {
    .testimonialsContainer {
        padding-left: 150px;
    }

    .card {
        width: 370px;
    }
}

@media only screen and (max-width:1300px) {
    .testimonialsContainer {
        padding-left: 100px;
    }

    .card {
        width: 360px;
    }
}

@media only screen and (max-width:1240px) {
    .testimonialsContainer {
        padding-left: 20px;
    }

    .card {
        width: 320px;
    }

    .cardImg {
        height: 320px;
    }
}

@media only screen and (max-width:1100px) {

    .card {
        width: 310px;
    }

    .cardImg {
        height: 300px;
    }

    .heading h5 {
        font-size: 40px;
        font-weight: 500;
    }
}

@media only screen and (max-width:1000px) {
    .titleNavigation {
        display: none;
    }

    .card {
        width: 360px;
    }

    .cardImg {
        height: 340px;
    }

    .navigation {
        display: none;
    }

}

@media only screen and (max-width:768px) {
    .heading {
        margin-bottom: 30px;
    }

    .heading h5 {
        font-size: 35px;
        text-align: center;

    }

    .card {
        width: 310px;
    }

    .cardImg {
        height: 290px;
    }

}

@media only screen and (max-width:700px) {
    .card {
        width: 404px;
    }

    .cardImg {
        height: 364px;
    }

}


@media only screen and (max-width:500px) {
    .heading {
        padding: 0 10px;
    }

    .card {
        width: 350px;
    }

    .cardImg {
        height: 330px;
    }

}

@media only screen and (max-width:400px) {
    .heading h5 {
        font-size: 30px;
    }

    .card {
        width: 300px;
    }

    .cardImg {
        height: 280px;
    }

    .cardContent {
        min-height: 280px;
    }

}