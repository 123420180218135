.banner img {
    max-width: 100%;
    border-radius: 1.2rem;
    aspect-ratio: 21/9;
    cursor: pointer;
}

.banner .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 25px;
}

.banner button {
    background-color: transparent;
    color: #1D9929;
    border: 1px solid #1D9929;
    padding: 8px 50px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.banner button:hover {
    background-color: #1D9929;
    color: #fff;

}

@media only screen and (max-width:768px) {
    .banner img {
        aspect-ratio: auto;
    }
}