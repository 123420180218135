.listingWrapper {
    grid-gap: 3.2em 2.3em;
    display: grid !important;
    grid-template-columns: repeat(3, minmax(auto, 28.3em));
    transform: unset;
    /* place-items: center; */
}

@media only screen and (max-width: 1210px) {
    .listingWrapper {
        grid-template-columns: repeat(2, minmax(auto, auto));
    }
}

@media only screen and (max-width: 700px) {
    .listingWrapper {
        grid-template-columns: repeat(1, minmax(auto, auto));
         place-items: center;
    }
}