.thankyouBox {
    height: 87vh;
    padding-top: 50px;
}


.logo {
    margin-bottom: 40px;
}

.messageTop {
    margin-bottom: 20px;
}


.messageTop h4 {
    margin-top: 12px;
    font-weight: 600;
}


.messageText, .messageTextBottom {
    margin-bottom: 20px;
}

.messageText p {
    font-size: 15px;
    line-height: 1.4;
}


.messageTextBottom p {
    font-size: 18px;
    margin-bottom: 0;
}

.contact {
    margin-top: 20px;
}

.contact p {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}


.btn button {
    background: #1D9929;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 2px solid #1D9929;
}

.btn button:hover {
    color: #1D9929;
    background-color: transparent;

}

.thankImage img, .logo img {
    width: 100%;
}


.paymentError {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: 70vh;
}

.errorbox h3 {
    font-size: 50px;
    font-weight: 600;
}

.errorButtons {
    margin-top: 20px;
    display: flex;
    gap: 20px
}

.errorButtons button {
    background: transparent;
    color: #1D9929;
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 2px solid #1D9929;
}

.errorButtons button:first-child {
    color: #fff;
    background-color: #1D9929;
    width: 150px;
}

.errorButtons button:last-child:hover {
    color: #fff;
    background-color: #1D9929;
}

.errorButtons button:first-child:hover {
    color: #1D9929;
    background-color: transparent;
}

.errorImage {
    display: flex;
    justify-content: center;
}

.errorImage img {
    width: 50%;
}



@media only screen and (max-width:1350px) {
    .thankyouBox {
        height: 80vh;
    }

}

@media only screen and (max-width:1100px) {
    .thankyouBox {
        height: 85vh;
    }

    .banner {
        bottom: 650px;
    }

    .detailsBox {
        padding: 0 80px;
        padding-top: 50px;
        padding-right: 200px;
    }

}

@media only screen and (max-width: 900px) {
    .thankyouBox {
        height: auto;
    }

    .detailsBox {
        padding: 0 30px;
        padding-top: 20px;
        margin-bottom: 50px;
    }

    .messageBoxes {
        gap: 20px;
    }

    .errorbox {
        padding-top: 20px;
        justify-content: flex-start;
        height: auto;
    }

    .errorImage {
        margin-top: 50px;
        justify-content: center;
    }
}

@media only screen and (max-width:500px) {
    .detailsBox {
        padding: 0 15px;
    }

    .errorbox h3 {
        font-size: 40px;
    }

    .errorbox p {
        font-size: 15px;
    }

    .errorButtons button {
        padding: 7px 8px;
        font-size: 17px;
    }

    .errorImage img {
        width: 100%;
    }

    .messageTop h4 {
        font-size: 26px;
    }
}