.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header h5 {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 10px;
}

.inputField {
    margin: 1.5rem 0;
    display: flex;
    gap: 2rem;
}

.inputField label {
    font-weight: 500;
}

.formButton {
    margin-top: 2rem;
}

.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #f8f8f8;
    border: 1px solid #e9ecef;
    border-radius: 2px;
    box-shadow: 0 0 8px #00000063;
    padding: 20px;
    border-radius: 10px;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modalHeader h5 {
    font-weight: 500;
    color: #000;
}

@media only screen and (max-width: 768px) {
    .inputField {
        flex-direction: column;
        gap: 1.5rem;
    }
}

@media only screen and (max-width: 500px) {
    .modalBox {
        width: 300px;
    }

    .header h5 {
        font-size: 22px;
    }
}