.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h5{
    font-weight: 700;
}

.dateSelectButton {
    width: 100%;
    display: flex;
    justify-content: center;
}

.dateSelectButton button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    border: 0;
    outline: 0;
    background-color: #fff;
    border-bottom: 2px solid rgb(226, 226, 226);
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 16px;
}

.dateButtonIcon {
    display: flex;
    align-items: center;
    height: 100%;
    background: rgb(240, 240, 240);
}

.dateButtonIcon svg {
    height: 2rem;
    width: 2rem;
}

.formButton {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

@media only screen and (max-width:600px){
    .header h5{
        font-size: 18px;
    }
}