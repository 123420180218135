.profileContainer h4 {
    font-weight: 700;
    margin: 1.5rem 0;
}

.profileTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form {
    margin: 2rem 0;
}

.inputField {
    margin: 1.5rem 0;
    display: flex;
    gap: 2rem;
}

.inputField label {
    font-weight: 500;
}

.formButton {
    margin: 2rem 0;
    display: flex;
    gap: 10px
}


.formButton button {
    padding: 10px 50px;
    border: none;
    border-radius: 0.5rem;
    background-color: #1D9929;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    font-size: 18px;
    transition: all 0.3s ease;
}

.formButton .editButton{
    background-color: transparent;
    border: 1px solid #1D9929;
    color: #1D9929;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 4px;
    text-transform: uppercase;
}

.formButton button:hover {
    box-shadow: 0 0 0px 2px #1D9929;
}

.formButton .editButton:hover{
    background-color: #1D9929;
    color: #fff;
}

.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #f8f8f8;
    border: 1px solid #e9ecef;
    border-radius: 2px;
    box-shadow: 0 0 8px #00000063;
    padding: 20px;
    border-radius: 10px;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modalHeader h5 {
    font-weight: 500;
    color: #000;
}

@media only screen and (max-width: 768px) {
    .inputField {
        flex-direction: column;
        gap: 1.5rem;
    }
}

@media only screen and (max-width: 500px) {
    .modalBox {
        width: 300px;
    }
}