.checkoutContainer {
    padding: 30px 0;
}

.cancelNotice {
    margin-bottom: 50px;
    background-color: #f8f8f8;
    padding: 12px;
    border-radius: 2px;
}

.cancelNotice p {
    color: #737373;
}

.leftTop {
    margin-bottom: 50px;

}

.infoFormBlur {
    filter: blur(3px);
    pointer-events: none;
    border: 1px solid #a6a6a670;
    box-shadow: 0 0 2px #737373;
    padding: 0 20px;
    border-radius: 5px;
    opacity: .8;
    margin-top: 10px;
}

.infoForm {
    pointer-events: none;
    border: 1px solid #a6a6a670;
    box-shadow: 0 0 2px #737373;
    padding: 0 20px;
    margin-top: 10px;
    border-radius: 5px;
}

.infoFormButton {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    width: fit-content;
    margin: auto;
    bottom: 45px;
    left: 10px
}

.infoFormButton button {
    font-size: 17px;
    font-weight: 700;
    background: none;
    cursor: pointer;
    border: 0;
    outline: none;
    color: #1D9929;
}

.infoFormButton p {
    font-size: 17px;
}

.infoHead {
    margin-bottom: 50px;
}

.info h5 {
    font-weight: 700;
    margin-bottom: 5px;
}

.info p {
    display: flex;
}

.info p svg {
    fill: gold;
}

.infoBody {
    display: flex;
    justify-content: space-between;
}

.infoBodyBox {
    display: flex;
    align-items: center;
    gap: 20px;
}

.infoBodyBox button svg {
    font-size: 40px;
}

.leftBottom h5 {
    font-weight: 600;
}

.inputField {
    margin: 1.5rem 0;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
}

.infoBox {
    display: flex;
    flex-direction: column;
}


.right {
    position: sticky;
    top: 140px;
    margin-left: 50px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 2px #737373;
    width: 75%;
}

.rightTop {
    border-bottom: 1px solid #a6a6a670;
}

.titleImage {
    height: 80px;
    margin-bottom: 10px;
}

.title h6 {
    max-width: 95%;
}

.image {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 10px;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dateTimeEdit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 10px;
}

.dateTime p {
    display: flex;
    align-items: center;
    color: #737373;
}

.dateTime p svg {
    margin-right: 5px;
    font-size: 22px;
}

.rightMid {
    padding: 15px 0;
    border-bottom: 1px solid #a6a6a670;
}

.rightMid p {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.rightMid p svg {
    margin-right: 10px;
    font-size: 25px;
}

.rightBottom {
    padding: 15px 0;
}

.price {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.amount, .totalAmount {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #a6a6a670;
    padding-bottom: 15px;
}

.amount p {
    font-size: 18px;
}

.pay {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 15px;
}

.pay>p>a {
    color: #0F0F43 !important;
    text-decoration: underline !important;
}

.pay button {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    border: 0;
    outline: 0;
    background-color: #1D9929;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
    border: 2px solid #1D9929;
}

.pay button:hover {
    color: #1D9929;
    background-color: transparent;
}

.pay button[disabled] {
    background-color: #a6a6a670;
    cursor: not-allowed;
}

.requestCallback {
    background-color: transparent !important;
    color: #1D9929 !important;
    border: 2px solid #1D9929 !important;
}

.requestCallback:hover {
    box-shadow: 0 0 5px #00ff1a !important;
}

@media only screen and (max-width:1000px) {
    .titleImage {
        height: 100%;
        margin-bottom: 10px;
    }

    .title h6 {
        font-size: 18px;
    }
}

@media only screen and (max-width:900px) {
    .title h6 {
        font-size: 20px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width:600px) {
    .infoHead h5{
        font-size: 25px;
    }

    .right {
        margin-left: 0px;
        width: 90%;
    }

    .dateTimeEdit {
        padding: 0;
    }

    .dateTime p {
        font-size: 15px;
    }

    .leftBottom {
        margin-bottom: 40px;
    }

    .infoFormButton {
        left: 0
    }
}