
.statsBox {
    display: grid!important;
    grid-gap: 3em 2em;
    grid-template-columns: repeat(4, minmax(auto, 28.3em));
}

.stats{
    margin: 20px 0;
}

.stats h2{
    margin: 20px 0;
}

.icon svg{
    font-size:100px;
}

.content {
    padding-left: 10px;
}

.content p{
    font-size: 0.9rem;
    font-weight: 400;
    max-width:90%;
    line-height: 1.5;
}

@media screen and (max-width: 768px) {
    .statsBox {
        grid-template-columns: repeat(2, minmax(auto, auto));
        gap: 1rem;
        place-items: center;
    }

    .icon svg{
        font-size:80px;
    }
    
    .content {
        padding-left:5px;
    }
    
    .content p{
        font-size: 0.8rem;
        font-weight: 400;
        max-width:100%;
        line-height: 1.5;
    }
    
}

