.filterItem, .filterItemActive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 2px;
    border-radius: 5px;
    transition: all 0.3s;
    padding: 5px;
    width: 80%;
    min-height: 80px;
    background-color: #f8f5f5;
    /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); */
}

.filterItemActive {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
    background-color: #1D9929;
    border: 1px solid rgba(0, 0, 0, 0.3);
    transform: translate(0, -5px);
}

.filterItemActive h6 {
    color: #fff;
}

.filterItem:hover {
    transform: translate(0, -5px);
}

.filterIcon img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.otherFilterItem {
    border: 1px solid #e6e6e6;
    transition: all 0.3s;
    background-color: #fffcfc;
    padding: 0 !important;
    border-radius: 20px;
}

.filterDrawer {
    width: 300px;
    padding: 10px 20px;
    box-sizing: border-box;
}

.filterDrawerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.filterDrawerBody {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 5px;
}

.price {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}