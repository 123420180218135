.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    width: 100%;
    max-width: 1100px;
    border-radius: 10px;
}

.modalHead {
    position: absolute;
    right: 0;
    z-index: 999;
}


.imageSlider {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.imageSlider img {
    aspect-ratio: 1.5/1;
    width: 100%;
}