.singleListing {
    margin: 30px auto;
}

.details {
    margin: 20px 0;
}

.details h1 {
    font-size: 23px;
}

.partner {
    margin: 12px 0 5px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
}

.partnerTitle, .partnerTitle h6 {
    display: flex;
    gap: 3px;
    font-size: 18px !important;
    font-weight: 500 !important;
    cursor: pointer;
    align-items: center;
    flex-wrap: wrap;
}

.partnerTitle span::after{
    content: "";
    width: 5px;
    height: 5px;
    background: #0000009c;
    color: #00000044;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin: 0 5px;

}

.stickyPriceBox {
    position: sticky;
    top: 20%;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
}

.priceBox {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(226, 226, 226);
    border-radius: 5px;
}

.priceBox .price {
    font-size: 30px;
    font-weight: 600;
    padding: 15px;
}

.priceBox .head {
    padding: 10px 15px;
    border-bottom: 1px solid rgb(226, 226, 226);
}

.priceBox .head p {
    display: flex;
    align-items: center;
}

.priceBox .head span {
    cursor: pointer;
}

.priceBox .head span svg {
    margin-left: 5px;
    margin-top: 5px;
    width: 1rem;
    height: 1rem;
}

.fee{
    padding: 20px;
    font-weight: 500;
}

.date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
}

.dateSelectButton,
.availabilityButton,
.mobileCalendarBtn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.dateSelectButton button {
    width: 90%;
    display: flex;
    align-items: center;
    height: 55px;
    justify-content: space-between;
    font-size: 1.2rem;
    border: 0;
    outline: 0;
    background-color: #fff;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 0.4em;
    padding-left: 8px;
    cursor: pointer;
    transition: all 0.3s;
}

.dateSelectButton button:hover {
    box-shadow: 0 0 10px #00000044;
}

.dateButtonIcon {
    display: flex;
    align-items: center;
    height: 100%;
    background: rgb(240, 240, 240);
}

.dateButtonIcon svg {
    height: 2.5rem;
    width: 2.5rem;
}

.availabilityButton {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.availabilityButton button {
    width: 88%;
    padding: 15px;
    font-size: 1.2rem;
    font-weight: bold;
    border: 0;
    outline: 0;
    background-color: #1d9929;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.timeAndLocation {
    margin-top: 10px;
    padding-left: 10px;
    font-style: italic;
}

.availabilityButton .schoolTripBtn {
    width: 88%;
    background-color: transparent;
    color: #1d9929;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 14px;
}

.schoolTripBtn {
    text-decoration: underline;
    margin: 5px 0;
}

.mobileCalendarBtn {
    margin: 10px 0;
    flex-direction: column;
    align-items: center;
}

.mobileCalendarBtn button {
    margin-top: 10px;
    width: 80%;
    padding: 13px;
    font-size: 1rem;
    font-weight: bold;
    border: 1px solid #1d9929;
    outline: 0;
    background-color: #1d9929;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.mobileCalendarBtn .schoolTripBtn {
    background-color: transparent;
    color: #1d9929;
    padding: 0;
    border: none;
    font-weight: 400;
    font-size: 14px;
}

.availabilityButton button:first-child:hover {
    box-shadow: 0 0 10px #0000008c;
}

.tagline {
    font-size: 0.8rem !important;
    text-align: center;
    padding: 8px !important;
    color: #1d9929;
}

.priceBottom {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(226, 226, 226);
    border-radius: 5px;
}

.priceBottom .head {
    padding: 10px 20px;
    border-bottom: 1px solid rgb(226, 226, 226);
}

.priceBottom .body {
    padding: 10px 20px;
}

.priceBottom .body .priceFeatures {
    display: flex;
    align-items: center;
    margin: 5px 0 10px 0;
}

.priceBottom .body .priceFeatures p {
    font-size: 0.9rem;
}

.priceBottom .body .priceFeatures .icon {
    margin-right: 10px;
    color: #1d9929;
}

.highlights {
    border-radius: 10px;
    padding: 32px 24px;
    background-color: #e9ffe9;
    margin-top: 30px;
}

.features {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    max-width: 100%;
    margin-bottom: 25px;
}

.feature {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #1D9929;
}

.feature svg {
    margin-right: 6px;
    margin-top: 1px;
    fill: #0000009c;
    width: 1em;
    height: 1em;
}

.feature img {
    width: 1em;
    height: 1em;
    margin-right: 10px;
}

.highlight {
    font-size: 1.1rem;
    line-height: 1.8rem;
    color: #1D9929;
    padding-left: 5px;
}

.accordion {
    padding-right: 15px;
}

.timeSlot {
    width: 90%;
    margin-top: 15px;
}

.noToursAvailable {
    padding: 20px;
    text-align: center;
}

.noToursAvailable p {
    font-size: 1.2rem;
    color: rgb(68, 68, 68);
}

.notAvailable button {
    margin-top: 10px;
    width: 80%;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    border: 1px solid #1d9929;
    outline: 0;
    background-color: transparent;
    color: #1d9929;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.notAvailable button:hover {
    box-shadow: 0 0 10px #0000008c;
}

.notAvailable .schoolTripBtn {
    width: 100%;
    background-color: transparent;
    color: #1d9929;
    padding: 0;
    display: flex;
    justify-content: center;
    border: none;
    font-weight: 400;
    font-size: 14px;

}

.notAvailable .schoolTripBtn:hover {
    box-shadow: none;
}

.host {
    border-bottom: 1px solid rgb(226, 226, 226);
    padding-top: 20px;
    padding-bottom: 50px;
}

.hostHead {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.hostHead h6 {
    font-size: 24px;
    font-weight: 600;
}

.hostBody {
    margin-top: 20px;
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}

.hostBody button{
    margin-left: -14px;
    text-decoration: underline;
    text-transform: none;
}

.hostBody button:hover{
    color: #1d9929;
    background: transparent;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .singleListing {
        overflow: hidden;
    }

    .highlights {
        padding: 32px 10px
    }

    .highlight {
        font-size: 14px;
        padding-left: 13px;
    }

    .features {
        grid-template-columns: auto auto auto;
        gap: 20px 0;
    }

    .feature {
        font-size: 15px;
        margin-left: 15px;
    }

    .feature svg {
        position: relative;
        right: 4px;
        margin-right: 5px;
    }

    .feature img {
        margin-right: 15px;
    }

    .stickyPriceBox {
        margin-left: 0px;
        position: static;
    }

    .priceBox {
        position: fixed;
        margin-bottom: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
    }

    .priceBottom {
        display: none;
    }

    .date {
        margin: 20px 0;
    }

    .accordion {
        padding-right: 0px;
    }
}

@media only screen and (max-width: 500px) {
    .singleListing {
        margin: 10px auto;
    }

    .container {
        padding: 0px !important;
    }

    .paddingContainer,
    .details {
        padding: 0 16px;
    }

    .partnerTitle, .partnerTitle h6 {
        font-size: 15px !important;
    }

    .highlights {
        padding: 28px 10px;
        margin-top: 30px;
    }


    .features {
        grid-template-columns: auto;
        gap: 13px;
    }

    .feature svg {
        width: 28px;
        height: 28px;
    }

    .feature img {
        width: 20px;
        height: 20px;
        margin-right: 14px;
    }

    .host {
        padding-left: 16px;
        padding-right: 16px;
    }

    .hostHead h6 {
        font-size: 22px;
    }

}