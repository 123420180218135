.heading {
    margin-bottom: 2rem;
}

.heading h2 {
    font-size: 24px;
    font-weight: 700;
    padding-left: 5px;
}

.card {
    width: 320px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 15px;
    border-color: rgb(232, 232, 232);
    box-shadow: rgba(28, 28, 28, 0.2) 0 0 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 10px;
    margin-top: 2px;
    padding: 10px;
    padding-bottom: 10px;
}

.cardImg {
    height: 210px;
    overflow: hidden;
    border-radius: 10px;
}

.cardImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.card:hover .cardImg img {
    transform: scale(1.05);
}


.cardContent {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
}

.cardContent h6 {
    font-size: 17px;
    font-weight: 500;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.cardContent p {
    font-size: 15px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    word-wrap: break-word;
    overflow: hidden;
    -webkit-box-orient: vertical;
}


@media only screen and (max-width:1200px) {
    .card {
        width: 305px;
    }

    .cardImg {
        height: 200px;
    }
}

@media only screen and (max-width:1000px) {
    .card {
        width: 360px;
    }

    .cardImg {
        height: 230px;
    }
}

@media only screen and (max-width:800px) {
    .card {
        width: 280px;
    }

    .cardImg {
        height: 140px;
    }
}


@media only screen and (max-width:600px) {
    .card {
        width: 260px;
    }

    .cardImg {
        height: 170px;
    }

    .heading h2 {
        font-size: 22px;
        padding-left: 5px;
    }
}