.card {
    display: flex;
    flex-direction: column;
    width: 340px;
    transition: all 0.3s;
    min-height: 330px;
}

.cardHover {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 0.5rem;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: all .3s ease;
}

.card:hover {
    transform: translate(0, -5px);
}

.card:hover .cardHover {
    opacity: 1;
}

.cardHoverText {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.cardImage {
    height: 200px;
    position: relative;
}

.cardImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.cardDetails {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
}

.cardDetailsTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ratings {
    display: flex;
    color: rgb(102, 102, 102);
    font-size: 14px;
}

.ratings span {
    display: flex;
    align-items: center;
    color: rgb(255, 0, 122);
}

.ratings span svg {
    height: 1rem;
    width: 1rem;
    margin: 1px 0 0 2px;
}

.country {
    font-size: 14px;
    color: rgb(102, 102, 102);
}

.cardDetailsBottom {
    margin: 5px 0;
}

.price {
    margin: 8px 0;
    font-size: 16px;
    color: rgb(102, 102, 102);
    font-weight: bold;
}

.price span {
    font-size: 12px;
    font-weight: normal;
}

.extra {
    margin-top: 5px;
    font-size: 14px;
    color: rgb(102, 102, 102);
    display: flex;
    margin-left: -5px;
}

.extra svg {
    margin-top: 2px;
    height: 18px;
}

@media only screen and (max-width:1200px){
    .card {
        width: auto!important;
    }
}