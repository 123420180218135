.imageSlider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}

.imageSlider img {
    aspect-ratio: 1.5/1;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

@media only screen and (max-width: 1000px) {
    .imageSlider {
        height: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .imageSlider {
        border-radius: 2px;
    }

    .imageSlider img {
        border-radius: 2px;
    }
}