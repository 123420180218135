.navContainer {
    background-color: #ffff !important;
    color: #000 !important;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 150px;
}

.navLeft img {
    width: 153px;
}

.navSearch {
    display: flex;
    justify-content: center;
    flex: 1
}

.navSearch input {
    background-color: transparent;
    border: 0;
    outline: 0;
    color: rgb(68, 68, 68);
    padding: 10px;
    font-size: 18px;
    width: 35vw;
}

.navSearch input::placeholder {
    color: #A6A6A6;
    font-size: 16px;
}

.searchIcon {
    position: relative;
    left: 10px;
    margin-right: 10px;
    top: 11px;
    color: rgb(68, 68, 68);
    width: 2.4rem;
    height: 2.4rem;
}

.navRight, .navRightLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin: 0 15px;
    cursor: pointer;
    padding: 6px;
    border-radius: 5px;
    transition: 0.4s;
}

.link:hover {
    background-color: rgba(0, 0, 0, 0.089);
}

.navbarCategories {
    padding: 10px 150px;
}

.userProfile, .userProfile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.userProfileBox {
    position: relative;
}

.userProfileMenu {
    position: absolute;
    background-color: #fff;
    top: 45px;
    left: -40px;
    padding: 5px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
    border-radius: 10px;
    z-index: 1;
}

.userProfileMenu::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 30px;
    width: 50px;
    height: 50px;
    background-color: #fff;
    transform: rotate(45deg);
    z-index: 0;
}

.menuItemProfile {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.menuItemLink, .menuItemSignOut {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px !important;
    font-weight: 500;
    color: #444444;
    padding: 1px;
}

.navbarCategoriesLinks {
    display: flex;
    align-items: center;
    width: 100%;
}

.navbarCategoriesLinks a {
    font-size: 16px;
    color: #212121;
    margin: 0 15px;
    cursor: pointer;
    padding: 6px;
    transition: 0.2s;
    text-decoration: none;
    font-weight: 500;
}

.navbarCategoriesLinks button {
    margin-top: 1px;
    font-size: 16px;
    color: #212121;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    font-weight: 500;
    margin-right: 15px;
}

.navbarCategoriesContainer {
    position: relative;
}

.navbarCategoriesLinks .menuIcon {
    cursor: pointer;
    border: 0;
    margin-right: 10px;
    margin-top: 1px;
}

.navbarCategoriesLinks a:hover, .navbarCategoriesLinks button:hover {
    color: #1D9929 !important;
}

.navbarCategoriesLinks .menuIcon:hover {
    color: #1D9929;
}

.dropdownContainer {
    position: absolute;
    max-height: 400px;
    background-color: #ffff !important;
    margin-top: -10px;
    padding: 5px 30px;
    top: 40px;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.dropdown {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 50px;
}

.dropdownItem {
    border-right: 1px solid #E5E5E5;
    min-height: 400px;
    max-height: 400px;
}

.dropdownItem:last-child {
    border-right: none;
}

.dropdownItemLinks {
    display: flex;
    margin-bottom: 10px;
    font-weight: 500;
    min-width: 250px;
    align-items: center;
    right: 5px
}

.dropdownItemLinks h6 {
    display: flex;
    align-items: center;
    color: #212121 !important;
    font-size: 16px;
    line-height: normal;
    margin: 10px 0;
    color: #212121;
    font-weight: 500;
}

.dropdownItemLinks h6 img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
}

.subMenuLink {
    margin: 12px 0;
    line-height: normal;
    font-weight: normal;
    cursor: pointer;
    transition: 0.4s;
    text-decoration: none;
    list-style: none;
}

.subMenuLink button {
    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: #212121 !important;
    font-size: 16px;
}



.subMenu>li>button:hover {
    color: #1D9929 !important;
}

.sidebar {
    width: 270px;
    padding: 10px;
    box-sizing: border-box;
}

.sidebarHead {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.closeBtn {
    position: relative;
    top: 10px;
}

.sidebarBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 5px;
}

.mobileSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: all 0.4s ease !important;
}


.mobileMenuLinks {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    gap: 5px;
    align-items: flex-start;
}

.mobileMenuLinks button {
    color: #0F0F43;
    text-transform: capitalize;
    font-weight: 500;
}


@media only screen and (max-width:1200px) {
    .navbar {
        padding: 0 50px;
    }

    .navbarCategories {
        padding: 10px 50px;
    }
}

@media only screen and (max-width:960px) {
    .navbar {
        padding: 0 10px;
    }

    .navbarCategories {
        padding: 10px 10px;
    }

    .navbarCategoriesLinks button {
        margin-right: 10px;
    }

    .navbarCategoriesLinks a {
        margin: 0 5px;
    }

}

@media only screen and (max-width:768px) {
    .navbar {
        padding: 0;
    }

    .navLeft{
        display :flex;
        position: relative;
        width: 40%;
    }

    .navRight{
        display :flex;
        position: relative;
    }

    .mobileLogo img{
        width: 130px;
    }
}