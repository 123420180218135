.signupContainer {
    padding: 30px 0;
}

.signupbox .loginForm h1,
.signupbox .otpForm h1 {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10px;
}

.signupImage {
    display: flex;
    justify-content: flex-end;
    height: 100%;
}

.signupImage img {
    max-width: 100%;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 0 2px #00000063;
}

.loginForm, .otpForm {
    max-width: 90%;
}

.otpBtn, .verifyBtn {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    border: 0;
    outline: 0;
    background-color: #1D9929;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s;
    border: 1px solid #1D9929;
    margin-top: 20px;
    padding: 12px;
    border-radius: 5px;
}

.otpBtn:hover, .verifyBtn:hover {
    color: #1D9929;
    background-color: transparent;
}

.otpBtn:disabled, .verifyBtn:disabled {
    background-color: #1d992994;
    cursor: not-allowed;
    border: none;
    color: #fff;
}

.errMsg {
    color: red;
    font-size: 14px;
    margin: 5px 0;
}


.otpForm form {
    margin-top: 24px;
}

.otpFormFooter .resendOtp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.otpFormFooter .resendOtp p {
    font-size: 16px;
    text-decoration: underline;
}

.otpFormFooter .terms {
    color: #737373;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
}

.otpFormFooter .terms p {
    color: #737373;
    text-align: left;
    font-size: 14px;
}

.otpFormFooter .terms a {
    color: #737373;
    text-decoration: underline !important;
}


@media only screen and (max-width: 900px) {
    .signupImage {
        margin-top: 30px;
        justify-content: center;
    }

    .loginForm, .otpForm {
        max-width: 100%;
    }
}

@media only screen and (max-width: 600px) {

    .signupbox .loginForm h1,
    .signupbox .otpForm h1 {
        font-size: 28px;
    }

    .otpFormFooter .terms p {
        color: #737373;
        text-align: left;
        font-size: 12px;
    }
}