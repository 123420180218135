
.errorContainer {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: 70vh;
}

.errorbox h3 {
    font-size: 50px;
    font-weight: 600;
}

.errorButtons {
    margin-top: 10px;
    display: flex;
}

.errorButtons button {
    background: #1D9929;
    color: #fff;
    padding: 7px 10px;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.errorButtons button:hover {
    box-shadow: 0 0 5px #1D9929;
}


.errorImage {
    display: flex;
    justify-content: center;
}

.errorImage img {
    max-width: 110%;
}


@media only screen and (max-width: 900px) {
    .errorbox {
        padding-top: 20px;
        justify-content: flex-start;
        height: auto;
    }

    .errorImage {
        margin-top: 50px;
        justify-content: center;
    }
}

@media only screen and (max-width:500px) {
    .errorbox h3 {
        font-size: 40px;
    }

    .errorbox p {
        font-size: 15px;
    }

    .errorButtons button {
        padding: 7px 8px;
        font-size: 17px;
    }

    .errorImage img {
        max-width: 105%;
    }
}