.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(90deg, #00c8ff65 0%, #92fe9d69 100%);
    background-size: 100% 45%;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #fff;
    border: 1px solid #e9ecef;
    box-shadow: 0 0 10px #00000063;
    padding: 30px;
    width: 600px;
    border-radius: 40px;
    min-height: 50vh;
    box-sizing: border-box;
    padding-bottom: 40px;
}


.head {
    display: flex;
    justify-content: flex-end;
}

.closeBtn {
    background-color: #cac6c6d2;
    border-radius: 50%;
}

.body, .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.image {
    height: 350px;
}

.image img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.text {
    gap: 5px;
    text-align: center;
}

.text h5{
    font-weight: 600;
}

.text h6{
    font-weight: 400;
    font-size: 18px;
    max-width: 400px;
}

@media only screen and (max-width:650px){
    .modal {
        width: 95%;
    }

    .image {
        height: 250px;
    }
}