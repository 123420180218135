.footer {
    margin-top: 50px;
    padding: 30px 0;
    padding-bottom: 40px;
    background-color: #f8f8f8;
    border-top: 1px solid #00000046;
}

.footerContainer {
    font-size: 16px;
}

.footerContainer * {
    color: #737373;
}

.footerLogoText * {
    text-align: left!important;
}

.footerLogoText p:first-child {
    margin: 10px 0 15px 0
}

.footerLogoTextBottom {
    margin-top: 50px;
}

.footerLogoTextBottom p {
    margin: 0 !important;
}

.footerLinksWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footerLinks {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footerLinks h6 {
    font-weight: 600;
    margin-bottom: 15px;
}

.footerLinks p {
    margin: 10px 2px
}

.footerLinks * {
    text-align: left;
}

.footerBottom {
    border-top: 1px solid #00000046;
    margin-top: 50px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
}

.footerBottomLeft {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.footerBottomRight {
    display: flex;
    align-items: center;
    gap: 15px;
}

.footerBottomRight a svg {
    font-size: 40px;
    margin-top: 3px;
    transition: all 0.3s;
}

.footerBottomRight a svg:hover {
    transform: skew(2deg, 10deg);
}

.footerBottomRight a:first-child svg:hover path {
    fill: #1519ff;
}

.footerBottomRight a:nth-child(2) svg:hover path {
    fill: #1596ff;
}

.footerBottomRight a:last-child svg:hover path {
    fill: #ff1515;
}

.helpSlider {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.helpSliderBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.helpSliderContent h5 {
    margin: 10px 0;
    text-align: center;
    text-transform: capitalize;
    color: #9c1313b9;
    font-size: 1.6rem;
}

.helpSliderContent h6 {
    font-size: 14px;
}

.helpSliderBox button {
    text-transform: uppercase;
    padding: 6px 16px;
    border-radius: 50px;
    background-color: #1D9929;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 0;
    width: 120px;
    margin-top: 20px;
}

.helpSliderBox button:hover {
    background-color: #1D9929;
    box-shadow: 0 0 5px #1D9929;
}


@media only screen and (max-width:850px) {
    .footerLinksWrapper {
        justify-content: space-around;
    }
}


@media only screen and (max-width:500px) {
    .footerLinksWrapper {
        justify-content: space-between;
    }

    .footerBottom {
        flex-direction: column;
        align-items: center;
    }

    .footerLogoTextBottom {
        margin-top: 20px;
    }

    .footerBottom {
        border-top: 1px solid #00000046;
        margin-top: 30px;
    }

    .footerBottomLeft {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 15px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .footerBottomRight {
        margin-top: 20px;
        gap: 20px;
    }

    .footerBottomRight a svg {
        font-size: 40px;
    }

}