.Slider{
    padding: 10px 20px;
}

.SliderHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SliderHeader h6{
    font-weight: 500;
}

.SliderBody{
    padding-bottom: 20px;
}

.link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin: 0 15px;
    cursor: pointer;
    padding: 6px;
    border-radius: 5px;
    transition: 0.4s;
}

.link:hover {
    background-color: rgba(0, 0, 0, 0.089);
}