@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", sans-serif !important;
}

a {
  text-decoration: none !important;
  color: #0f0f43;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li {
  color: #0f0f43;
}

/* li {
  list-style: none !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: "" !important;
}

.arrows-lg {
  width: 50px;
  border-radius: 50%;
}

.arrows-sm {
  width: 30px;
  border-radius: 50%;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  transition: all 0.3s !important;
}

/* ----------------------Calendar CSS---------------------- */
.react-calendar {
  max-width: 100%;
  background-color: #fff !important;
  border: 0 !important;
  box-shadow: none !important;
}

.react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__tile {
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  line-height: 20px !important;
}

.react-calendar__tile:enabled {
  background-color: transparent;
  border: 0;
  color: #000;
  font-weight: bold !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #f8f8f8 !important;
  color: #000000 !important;
}

.react-calendar__tile--active {
  background-color: #f8f8f8 !important;
  border: 1px solid #ddd !important;
  border-radius: 50% !important;
}

.react-calendar__tile:disabled,
.react-calendar__tile--weekend:enabled:active,
.react-calendar__tile--weekend:enabled:hover,
.react-calendar__tile--weekend:enabled:focus,
.react-calendar__tile--prev-month:enabled:active,
.react-calendar__tile--prev-month:enabled:hover,
.react-calendar__tile--prev-month:enabled:focus,
.react-calendar__tile--next-month:enabled:active,
.react-calendar__tile--next-month:enabled:hover,
.react-calendar__tile--next-month:enabled:focus {
  background-color: transparent !important;
  border: 0 !important;
  color: #ccc !important;
}

.react-calendar__tile--weekend {
  color: #ccc !important;
}

.react-calendar__tile--prev-month:enabled,
.react-calendar__tile--next-month:enabled {
  color: #ccc !important;
  pointer-events: none;
}

/* .swiper-pagination-bullet {
  background-color: #ffffff!important; 
  border: 1px solid #6f48eb!important;
  color: #000!important;
  width: 15px!important; 
  height: 15px!important; 
} */

@media only screen and (max-width: 768px) {
  html {
    scroll-behavior: smooth;
  }
}

@media only screen and (max-width: 600px) {
  .hide-arrow {
    display: none !important;
  }
}
