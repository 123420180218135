.head h4 {
    font-weight: 600;
    margin: 1.5rem 0;
}


.bookingItem {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid #918f8f;
    padding-bottom: 20px;
    flex-wrap: wrap;
    margin: 2rem 0;
}

.left {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.right {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}

.right h5, h6{
    font-weight: 500;
}

.rightItem {
    display: flex;
    gap: 1.5rem;
}

.imageBox {
    height: 150px;
    aspect-ratio: 1.5/1;
}

.imageBox img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.notAvailable{
    display: grid!important;
    place-items: center;
    gap: 1rem;
}

@media only screen and (max-width:800px) {
    .bookingItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        justify-content: center;
    }

    .rightItem {
        display: flex;
        width: 100%;
        gap: 1.5rem;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width:530px) {
    .imageBox {
        display: flex;
        justify-content: center;
        height: auto;
    }

    .imageBox img {
        aspect-ratio: 1.5/1;
    }
}