.heading {
    margin-bottom: 2rem;
}

.heading h2 {
    font-size: 24px;
    font-weight: 700;
    padding-left: 5px;
}

.listingWrapper {
    grid-gap: 3.2em 2.3em;
    display: grid !important;
    grid-template-columns: repeat(1, minmax(auto, 1fr));
    transform: unset
}


.card {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
}

.card .left {
    height: 100%;
    overflow: hidden;
}

.card .left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card:hover .left img {
    transform: scale(1.05);
}

.card .right {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.card .right h6 {
    font-weight: 500;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    overflow: hidden;
    -webkit-box-orient: vertical;
    line-height: 28px;
}

.card .right .highlight p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    word-wrap: break-word;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.card .right .details,
.card .right .details .detail {
    display: flex;
    gap: 5px;
    align-items: center;
}

.card .right .details {
    gap: 5px;
    flex-wrap: wrap;
}


@media only screen and (max-width: 864px) {
    .heading h2 {
        font-size: 22px;
        padding-left: 5px;
    }

    .card {
        height: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .card .right {
        padding: 0.5rem;
    }

    .card .right .details {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}