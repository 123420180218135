.wrapper {
    padding: 50px 0;
    border-radius: 10px;
}

.left, .right, .mid {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mid .box {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mid .box h1 {
    font-weight: 700;
    font-size: 32px;
    text-align: center;
}

.mid .box h6 {
    font-weight: 700;
    text-align: center;
    font-size: 18px;
}

.mid .box .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
}

.mid .box .btns a {
    background-color: transparent;
    color: #1D9929;
    border: 1px solid #1D9929;
    padding: 8px 50px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.mid .box .btns a:hover {
    background-color: #1D9929;
    color: #fff;
}

.left .images1, .left .images2,
.right .images1, .right .images2 {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin: 0 20px;
}

.left img,
.right img {
    max-width: 100%;
    height: 100px;
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: 5px;
}

.left {
    justify-content: flex-start;
}

.right {
    justify-content: flex-end;
}

@media only screen and (max-width:900px) {
    .wrapper {
        justify-content: center;
        gap: 30px;
    }

    .left, .right, .mid {
        gap: 20px;

    }

    .left .images1, .left .images2,
    .right .images1, .right .images2 {
        gap: 20px;
        margin: 0;
        justify-content: center;
    }

    .left img,
    .right img {
        height: 120px;
    }
}

@media only screen and (max-width:500px) {
    .mid .box h1 {
        font-size: 28px;
        padding: 0 10px;
    }
}