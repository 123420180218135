.contactContainer {
    padding: 60px 0;
}

.contactbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.contactbox h3 {
    font-size: 50px;
    font-weight: 600;
}

.contactImage {
    display: flex;
    justify-content:flex-end;
    height: 100%;
}

.contactImage img {
    max-width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.boxes {
    display: flex;
    margin-top: 50px;
    gap: 50px;
}

.boxes .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px;
    width: 180px;
    height: 180px;
    box-shadow: 0 0 8px #00000063;
    border-radius: 10px;
}

.boxes .box h6 {
    font-weight: 700;
}

.boxes .box svg {
    background-color: #1D9929;
    padding: 5px;
    border-radius: 50%;
    color: #fff;
}


@media only screen and (max-width: 900px) {
    .contactContainer {
        padding: 40px 0;
    }

    .contactImage {
        margin-top: 50px;
        justify-content: center;
    }

    .boxes {
        justify-content: center;
        flex-wrap: wrap;
    }

}

@media only screen and (max-width:500px) {
    .contactbox h3 {
        font-size: 40px;
    }

    .contactbox p {
        font-size: 15px;
    }

    .boxes .box {
        width: 200px;
        height: 200px;
    }
}