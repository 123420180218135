.card {
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    max-width: 350px;
}

.cardHover {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 0.5rem;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: all .3s ease;
}

.card:hover {
    transform: translate(0, -5px);
}

.card:hover .cardHover {
    opacity: 1;
}

.cardHoverText {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.cardImage {
    height: 200px;
    position: relative;
}

.cardImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.cardDetails {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
}

.cardDetailsTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.cardDetailsBottom {
    margin: 5px 0;
}

.price {
    margin: 8px 0;
    font-size: 16px;
    color: rgb(102, 102, 102);
    font-weight: bold;
}

.price span {
    font-size: 12px;
    font-weight: normal;
}

@media only screen and (max-width:600px){
    .card {
        max-width: 100%;
    }
}