.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
}

.heading h2 {
    font-size: 24px;
    font-weight: 700;
    padding-left: 5px;
}

.seeAllBtn {
    display: flex;
    align-items: center;
}

.seeAllBtn * {
    color: #737373;
}

.seeAllBtn a {
    font-size: 16px;
    font-weight: 500;
}

.seeAllBtn a:hover {
    text-decoration: underline !important;
}

.card {
    width: 365px;
    background-color: #f8f8f8;
    padding-bottom: 10px;
    cursor: pointer;
    border-radius: 2px;
    min-height: 390px;
    transition: all 0.3s ease;
    border-radius: 1.5rem;
    border-color: rgb(232, 232, 232);
    box-sizing: rgba(28, 28, 28, 0.12) 0px 0.4rem 1.8rem;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.cardImg {
    height: 230px;
    overflow: hidden;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
}

.cardImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
}

.card:hover .cardImg img {
    transform: scale(1.05);
}

.cardContent {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 130px;
}

.cardContent h6 {
    font-size: 18px;
    font-weight: 500;
}

.cardDateDuration {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.cardDateDuration * {
    color: #737373;
}

.cardDateDurationBox {
    display: flex;
    gap: 5px;
}

.cardDateDurationBox h6 {
    font-size: 16px;
    font-weight: 400;
}

@media only screen and (max-width:1200px) {
    .card {
        width: 305px;
    }

    .cardImg {
        height: 190px;
    }
}

@media only screen and (max-width:1000px) {
    .card {
        width: 360px;
    }

    .cardImg {
        height: 230px;
    }
}

@media only screen and (max-width:800px) {
    .card {
        width: 280px;
    }

    .cardImg {
        height: 140px;
    }
}


@media only screen and (max-width:600px) {
    .card {
        width: 280px;
        min-height: 340px;
    }

    .cardImg {
        height: 170px;
    }

    .cardContent {
        height: 140px;
    }

    .heading h2 {
        font-size: 22px;
        padding-left: 5px;
        max-width: 230px;
    }
}