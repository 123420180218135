.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 2rem
}

.filterItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s;
    height: 120px;
    overflow: hidden;
}

.filterItem img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.filterItem:hover img {
    transform: scale(1.1);
}

@media only screen and (max-width: 500px) {
    .wrapper {
        grid-template-columns: repeat(auto-fit, minmax(95px, 1fr));
        grid-gap: 1rem
    }

    .filterItem {
        height: 80px;
    }

}