.listings {
    margin: 40px 0;
    overflow: hidden;
}

.listingsHeader {
    margin-bottom: 20px;
}

.listingsHeader h1 {
    padding-left: 15px;
    font-size: 24px;
}

.filters {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.otherFilters {
    margin-bottom: 30px;
}

.filtersBtn {
    display: flex;
    justify-content: flex-end;
    padding-right: 35px;
    margin-top: 20px;

}

.filterLoading .boxWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 20px;
}

.filterLoading .boxWrapper2 {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    padding: 0 20px;
    gap: 50px;
}

.filterLoading .boxWrapper .box1 {
    height: 80px;
    width: 100px;
}

.filterLoading .boxWrapper2 .box2 {
    height: 30px;
    width: 100px;
}

.listingsWrapperHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listingWrapper, .listingWrapperSkeleton {
    grid-gap: 2.3em;
    display: grid !important;
    grid-template-columns: repeat(3, minmax(auto, 28.3em));
    place-items: center;
}

.showMoreBtn button {
    margin: 0 auto;
    width: 200px;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px 30px;
    font-size: 1.4rem;
    font-weight: 500;
    color: #000;
    cursor: pointer;
    transition: all 0.2s ease;
}

.showMoreBtn button:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}



@media only screen and (max-width: 1200px) {
    .listingWrapper {
        place-items: initial
    }

    .listingWrapperSkeleton {
        place-items: center;
    }
}

@media only screen and (max-width: 768px) {
    .listingWrapper, .listingWrapperSkeleton {
        grid-template-columns: repeat(2, minmax(auto, auto));
    }
}

@media only screen and (max-width: 500px) {
    .listingWrapper, .listingWrapperSkeleton {
        grid-template-columns: repeat(1, minmax(auto, auto));
    }

    .listingsHeader h1 {
        padding-left: 5px;
        font-size: 22px;
    }
}