.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #f8f8f8;
    border: 1px solid #e9ecef;
    border-radius: 2px;
    box-shadow: 0 0 8px #00000063;
    padding: 10px;
    border-radius: 10px;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modalHeader h6 {
    font-weight: 500;
    color: #000;
}

.date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    width: 100%;
}

.dateSelectButton,.availabilityButton{
    width: 100%;
    display: flex;
    justify-content: center;
}

.dateSelectButton button {
    width: 100%;
    display: flex;
    align-items: center;
    height: 55px;
    justify-content: space-between;
    font-size: 1.2rem;
    border: 0;
    outline: 0;
    background-color: #fff;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 0.4em;
    padding-left: 8px;
    cursor: pointer;
    transition: all 0.3s;
}

.dateSelectButton button:hover {
    box-shadow: 0 0 10px #00000044;
}

.dateButtonIcon {
    display: flex;
    align-items: center;
    height: 100%;
    background: rgb(240, 240, 240);
}

.dateButtonIcon svg {
    height: 2.5rem;
    width: 2.5rem;
}

.timeSlot {
    width: 100%;
    margin-top: 15px;
}

.availabilityButton {
    margin-top: 30px;
}

.timeAndLocation {
    margin-top: 10px;
    padding-left: 10px;
    font-style: italic;
}

.availabilityButton button {
    width: 100%;
    padding: 15px;
    font-size: 1.2rem;
    font-weight: bold;
    border: 0;
    outline: 0;
    background-color: #1D9929;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}


.availabilityButton button:hover {
    box-shadow: 0 0 10px #0000008c;
}

@media only screen and (max-width: 500px) {
    .modalBox {
        width: 300px;
    }
}