.homeTopBanner {
  height: 75vh;
}

.homeTopBanner img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.searchBox {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 30px;
  height: 20px;
  z-index: 1;
}

.searchInner {
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  box-shadow: 0 0px 10px rgb(0 0 0 / 50%);
  width: 35vw;
  border-radius: 10px;
  font-size: 20px;
  position: relative;
  bottom: 30px;
  padding: 0 5px;
}

.searchInner input {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: rgb(68, 68, 68);
  padding: 15px;
  font-size: 18px;
  width: 100%;
}

.searchInner input::placeholder {
  color: #a6a6a6;
  font-size: 16px;
}

.searchIcon {
  position: relative;
  left: 5px;
  color: rgb(68, 68, 68);
  width: 2.4rem;
  height: 2.4rem;
}

.searchInner button {
  padding: 12px 20px;
  cursor: pointer;
  font-size: 20px;
  outline: 0;
  border: 0;
  border-radius: 5px;
  background-color: #1d9929;
  color: #fff;
  font-weight: bold;
}

.bannerWrapper,
.statsWrapper,
.signUP {
  margin: 50px 0;
}

.signUP {
  padding: 50px 0;
  background-color: #f8f8f8;
}

.signUP .signUPContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.signUP .signUPContainer h3 {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}

.signUP .signUPContainer button {
  background-color: transparent;
  color: #1d9929;
  border: 1px solid #1d9929;
  padding: 8px 20px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.signUP .signUPContainer button:hover {
  background-color: #1d9929;
  color: #fff;
}

.banner {
  background: rgb(248, 246, 255);
  border-radius: 1.2rem;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.bannerLeft {
  padding: 6.4rem 7.8rem 8.4rem;
}

.bannerLeft p {
  color: rgb(68, 68, 68);
  margin: 10px 0;
}

.bannerBtn {
  margin-top: 30px;
}

.bannerBtn a {
  margin-right: 20px;
  padding: 10px 20px;
  background-color: #1d9929;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0px 5px rgba(255, 217, 0, 0.781);
}

.bannerBtn a:hover {
  box-shadow: 0 0px 5px rgba(255, 0, 0, 0.781);
  color: #000;
}

.bannerRight img {
  color: transparent;
  max-width: 100%;
  height: auto;
}

/* Stats */

.statsWrapper {
  margin: 50px 0;
}

.testimonials {
  padding: 50px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.upcoimgExps,
.blogs {
  padding: 50px 0;
  background-color: #faf1ed;
}

.filters {
  background-color: #f8f8f8;
  padding: 50px 0;
}

.filters .filterSlider .filterSliderHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 2rem;
}

.filters .filterSlider .filterSliderHeader .filterSliderContent h4 {
  font-size: 24px;
  font-weight: 700;
}

.blogs {
  background-color: #ffff;
}

@media only screen and (max-width: 1300px) {
  .searchInner input {
    font-size: 15px;
  }

  .searchInner {
    width: 45vw;
  }
}

@media only screen and (max-width: 1000px) {
  .homeTopBanner {
    height: 100%;
  }

  .searchInner {
    width: 60vw;
  }

  .banner {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 768px) {
  .homeContainer {
    overflow: hidden;
  }

  .searchInner {
    width: 70vw;
  }

  .searchInner input {
    padding: 15px;
    width: 100%;
  }

  .searchInner button {
    padding: 8px;
  }

  .searchIcon {
    left: 0;
    top: 2px;
    color: #fff;
  }

  .bannerLeft {
    padding: 2rem 0.5rem;
  }

  .bannerBtn a {
    margin-right: 10px;
    padding: 8px 16px;
  }
}

@media only screen and (max-width: 500px) {
  .searchInner {
    width: 80vw;
  }

  .bannerBtn a {
    margin-right: 10px;
    padding: 8px 10px;
  }

  .filters .filterSlider .filterSliderHeader .filterSliderContent h4 {
    font-size: 22px;
  }
}
