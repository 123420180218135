.signInBtn {
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: #1D9929;
    color: #fff;
    border: 1px solid #fff;
}

.signInBtn:hover {
    border: 1px solid #1D9929;
    color: #1D9929;
    background-color: transparent;
}

.loginModal, .otpModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border: 1px solid #e9ecef;
    border-radius: 2px;
    box-shadow: 0 0 8px #00000063;
    border-radius: 5px;
    padding: 10px;
    width: 830px
}

.loginModalHead button, .otpModalHead button {
    position: absolute;
    top: 0;
    right: 0;
}

.loginModalBody, .otpModalBody {
    display: flex;
    gap: 40px;
}

.loginModalBodyLeft, .otpModalBodyLeft {
    border-radius: 10px;
    height: 450px;
}


.loginModalBodyLeft img, .otpModalBodyLeft img {
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 0 2px #00000063;
}

.loginModalBodyRight, .otpModalBodyRight {
    margin-top: 40px;
}

.loginModalBodyRightTop h6 {
    margin-top: 15px;
}

.loginModalBodyRight, .otpModalBodyRight form {
    margin-top: 10px;
}

.otpBtn, .verifyBtn {
    width: 100%;
    padding: 8px;
    font-size: 18px;
    font-weight: bold;
    border: 0;
    outline: 0;
    background-color: #1D9929;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s;
    border: 1px solid #1D9929;
    margin-top: 20px;
}

.otpBtn:hover, .verifyBtn:hover {
    color: #1D9929;
    background-color: transparent;
}

.otpBtn:disabled, .verifyBtn:disabled {
    background-color: #1d992994;
    cursor: not-allowed;
    color: #fff;
    border: none;
}

.errMsg {
    color: red;
    font-size: 14px;
    margin: 5px 0;
}

.loginModalBodyLeft h6 {
    margin-bottom: 25px;
    font-weight: 500
}

.otpModalBodyRight form {
    width: 300px;
}

.leftHead h5 {
    margin-bottom: 5px;
}

.leftHead p {
    text-align: left;
    padding-left: 2px;
}

.leftBody {
    margin-top: 20px;
}

.resendOtp {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.resendOtp p {
    font-size: 14px;
    cursor: pointer;
    margin: 10px 0;
    text-decoration: underline;
}

.leftFooter {
    margin: 20px 0;
}

.terms p {
    color: #737373;
    text-align: left;
    font-size: 10px;
}

.terms a {
    color: #737373;
    text-decoration: underline !important;
}



@media only screen and (max-width:1000px) {
    .loginModal, .otpModal {
        width: 700px
    }

    .loginModalBodyLeft, .otpModalBodyLeft {
        height: 350px;
    }

    .loginModalBodyRight {
        margin-top: 0px;
    }


}

@media only screen and (max-width:768px) {
    .loginModal, .otpModal {
        width: 450px;
        min-height: 400px;
    }

    .loginModalBody, .otpModalBody {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .loginModalBodyLeft, .otpModalBodyLeft {
        display: none;
    }

    .otpModalBodyRight form {
        width: 100%;
    }
    
    .leftHead, .leftFooter {
        padding: 0 15px;
    }

    .otpModalBodyRight input {
        width: 100% !important;
        aspect-ratio: 1/1 !important;
    }

}

@media only screen and (max-width:500px) {
    .loginModal, .otpModal {
        width: 97%;
        box-sizing: border-box;
        padding: 30px 0;
        min-height: 445px;
    }

    .loginModalBody {
        gap: 0;
        padding: 0;
    }

    .loginModalBodyRight{
        width: 320px;
    }

    .otpModalBodyRight input {
        font-size: 1.2rem !important;
    } 

}