.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border: 1px solid #e9ecef;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px;
    padding: 20px;
    border-radius: 20px;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modalHeader h6 {
    font-weight: 500;
    color: #000;
}

.modalButton{
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 500px) {
    .modalBox {
        width: 300px;
    }
}