.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h5 {
    font-weight: 700;
}

.inputField {
    margin: 1.5rem 0;
}

.inputField label {
    font-weight: 500;
}

.formButton {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
}