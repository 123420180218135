.collectionsHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
}

.collectionsHead h2 {
    font-size: 24px;
    font-weight: 700;
    padding-left: 15px;
}

.seeAllBtn {
    display: flex;
    align-items: center;
}

.seeAllBtn * {
    color: #737373;
}

.seeAllBtn a {
    font-size: 16px;
    font-weight: 500;
}

.seeAllBtn a:hover {
    text-decoration: underline !important;
}

@media only screen and (max-width:500px) {

    .collectionsHead h2 {
        font-size: 22px;
        padding-left: 5px;
        max-width: 230px;
    }
}