.profileBoxTop {
    height: 550px;
}

.topImage {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px;
    background-blend-mode: darken;
    filter: brightness(0.9) contrast(1.1);
    object-fit: cover;
}

.profileContent {
    position: relative;
    bottom: 50px;
}

.profileContentImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 0 5px;
}

.profileContentImage img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profileContentInfo {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.profileContentInfo {
    margin-top: 20px;
}

.profileContentInfo h6 {
    font-size: 28px;
    font-weight: 600;
    line-height: 20px;
}

.location {
    display: flex;
    position: relative;
    top: 5px;
    right: 1px;
    font-size: 1.175rem !important;
}

.profileContentInfo p:last-child {
    margin-top: 20px;
    padding-left: 4px;
    font-size: 1.175rem;
}

.profileContentSocial {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: -8px;
}

.tabs {
    margin-top: 50px;
}

.experienceBox, .mediaBox {
    display: grid;
    grid-gap: 3.2em 2.3em;
    grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
}

.mediaBoxImage {
    aspect-ratio: 1.5/1;
    cursor: pointer;
    position: relative;
    transition: all .3s;
}

.mediaBoxImage img {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px;
    border-radius: 10px;
}

.cardHover {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 0.5rem;
    bottom: 0;
    height: 99%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: all .3s ease;
}

.cardHoverText {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.mediaBoxImage:hover {
    transform: scale(1.01);
}

.mediaBoxImage:hover .cardHover {
    opacity: 1;
}


@media only screen and (max-width:1100px) {
    .mediaBox {
        display: flex;
        gap: 3rem;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media only screen and (max-width:768px) {
    .tabs {
        margin-top: -70px;
        border-top: 1px solid #ccc;
        padding-top: 20px;
    }

    .experienceBox {
        place-items: center;
    }

    .profileBoxTop {
        height: auto;
        width: 100%;
        aspect-ratio: 21/9;
    }
}

@media only screen and (max-width:500px) {

    .profileContentInfo h6 {
        font-size: 24px;
        font-weight: 700;
    }

}