.imageSlider {
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.imageSlider img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}

@media only screen and (max-width: 1000px) {
    .imageSlider {
        height: 100%;
    }
}