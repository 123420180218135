.aboutBox {
    padding: 1.5rem 0;
}

.section1 {
    padding-bottom: 50px;
    border-bottom: 1px solid;
}

.aboutTitle {
    text-align: center;
    margin-bottom: 30px;
}

.aboutTitle h3 {
    font-weight: 600;
    margin: 2px 0;
}

.aboutImages {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 420px;
    gap: 30px;
}

.aboutImagesLeft, .aboutImagesRight {
    display: flex;
    gap: 15px;
    align-items: center;
}

.leftImagesLeft,
.leftImagesRight,
.rightImagesLeft,
.rightImagesRight {
    width: 180px;
}

.leftImagesLeft img, .rightImagesRight img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 10px;
}


.leftImagesRight img:first-child {
    width: 100%;
    aspect-ratio: 1.5/1;
    object-fit: cover;
    border-radius: 10px;
    margin: 5px 0;
}

.rightImagesLeft img:first-child {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 10px;
    margin: 5px 0;
}

.leftImagesRight img:last-child {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 10px;
}

.rightImagesLeft img:last-child {
    width: 100%;
    aspect-ratio: 1.5/1;
    object-fit: cover;
    border-radius: 10px;
}

.aboutImagesCenter {
    position: relative;
    height: 100%;
    width: 240px;
}

.aboutImagesCenter img {
    height: 100%;
    width: 240px;
    object-fit: cover;
    border-radius: 10px;
}

.centerImgStar {
    position: absolute;
    left: 40%;
    top: -18px;
}

.quoteText {
    margin-top: 10px;
    padding-left: 50px;
    max-width: 350px;
}

.quoteText h6:last-child {
    position: relative;
    display: inline-block;
    font-weight: bold;
    font-size: 18px;
    color: #333;
    padding: 10px 0;
    background-color: #FFF;
}


.section2 {
    min-height: calc(100vh - 100px);
    padding: 50px 0;
}

.section2Container {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
}

.section2Titles h2 {
    font-weight: 500;
}

.section2Titles h2:last-child {
    color: #33333393;
}

.section2Desc {
    padding-bottom: 10px;
}

.section2Desc {
    display: flex;
    align-items: flex-end;
}

.section2Image {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 550px;
}

.section2Image img {
    max-width: 100%;
    border-radius: 1.2rem;
    aspect-ratio: 21/9;
    object-fit: cover;
}

.section3Container {
    padding: 50px 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
}

.section3Content {
    box-shadow: 0 0 5px #00000063;
    padding: 40px;
    align-items: center;
    border-radius: 20px;
    background-color: #032224;
}

.section3Content h3 {
    text-align: center;
    color: #fff;
}

.section3Content .right {
    box-shadow: 0 0 5px #00000063;
    padding: 40px 60px;
    border-radius: 20px;
    background-color: #fff;
}

.section3Content .right h6,
.section3Content .right .section3Desc p {
    margin-bottom: 30px;
    font-size: 25px;
    max-width: 500px;
}

.section3Content .right .section3Desc p {
    font-size: 22px;
}

.section3Content .right .section3Profile h6 {
    margin-bottom: 0;
}

.section3Profile {
    display: flex;
    gap: 10px;
}

.section3Profile img {
    width: 60px;
    height: 60px;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
}

.section3Stats {
    padding: 50px 0;
}

.section3StatsContent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 50px;
}

.section3Desc h6 {
    margin-bottom: 20px;
}

.section3StatBox h3 {
    margin-top: 10px;
    font-weight: 600;
}

.section4 {
    min-height: calc(100vh - 100px);
    padding-top: 50px;
    background: #f8f8f8;
}

.section4Container h3 {
    font-weight: 600;
    border-bottom: 1px solid;
    padding-bottom: 40px;
}

.teamWrapper {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 80px;
}

.teamBox {
    width: 320px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.teamBox img {
    max-width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 0 1px #0000009d;
}

.teamBox .social {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 0.5rem;
    bottom: 0;
    height: 320px;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .7);
    transition: all .3s ease;
}

.teamBox .social span {
    cursor: not-allowed;
}


.teamBox:hover .social {
    opacity: 1;
}


.teamBox h5 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
}

.teamBox h6 {
    text-align: center;
}

.section4Border {
    border-bottom: 1px solid;
    margin-top: 100px;
}

.joinTeam {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 50px;
    padding-bottom: 100px;
}

.joinTeamTitle h3 {
    border-bottom: none;
}

.joinTeamContent a {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}

.section5 {
    background: #032224;
    padding: 80px 0;
}

.section5Container {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
}

.section5Titles h2 {
    font-weight: 500;
    margin-bottom: 30px;
    color: #fff;
}

.section5Titles p {
    color: #fff;
    padding-bottom: 50px;
}

.contact {
    color: #000;
    border-top: 1px solid #fff;
    padding-top: 50px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.contact button {
    padding: 12px 20px;
    width: 140px;
    color: #000;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.contact button:hover {
    box-shadow: 0 0 5px #004196d7;
}

.contact h6 {
    color: #fff;
}

.section5Img {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.section5Img img {
    height: 100%;
    max-width: 65%;
    object-fit: cover;
    border-radius: 10px;
}


@media only screen and (max-width:1100px) {

    .leftImagesLeft,
    .leftImagesRight,
    .rightImagesLeft,
    .rightImagesRight {
        width: 140px;
    }

    .aboutImagesCenter {
        width: 180px;
    }

    .aboutImagesCenter img {
        width: 180px;
    }

    .section3StatsContent {
        flex-wrap: wrap;
        justify-content: center;
    }

}

@media only screen and (max-width:870px) {

    .leftImagesLeft,
    .leftImagesRight,
    .rightImagesLeft,
    .rightImagesRight {
        width: 125px;
    }

    .aboutImagesCenter {
        width: 165px;
    }

    .aboutImagesCenter img {
        width: 165px;
    }

    .section3Content h3 {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width:768px) {

    .leftImagesLeft,
    .leftImagesRight,
    .rightImagesLeft,
    .rightImagesRight,
    .centerImgStar {
        display: none;
    }

    .aboutTitle h3, .section4Container h3{
        font-size: 38px;
    }

    .aboutImages {
        height: auto;
    }

    .aboutImagesCenter {
        width: 100%;
        height: 300px;
    }

    .aboutImagesCenter img {
        width: 100%;
    }

    .quoteText {
        padding-left: 0;
        max-width: 100%;
    }

    .section2Container, .section3Content, .section5Container {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 10px;
    }

    .section3Content {
        padding: 40px;
    }

    .section3Content .left {
        width: 100%;
    }

    .joinTeam {
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
    }

    .section2Titles {
        margin-bottom: 10px;
    }

    .section2Image {
        margin-top: 50px;
        height: 100%;
    }

    .section2Image img {
        aspect-ratio: auto;
    }

    .section3Profile {
        margin-bottom: 20px;
    }

    .contact button {
        padding: 12px;
        width: 120px;
    }

    .contact h6 {
        color: #fff;
        font-size: 16px;
    }

    .section5Img {
        display: none;
    }

    .teamWrapper {
        gap: 50px;
    }

    .teamBox {
        width: 300px;
    }

}

@media only screen and (max-width:500px) {

    .aboutTitle h3 {
        font-size: 36px;
    }

    .quoteText h6 {
        text-align: center;
        font-size: 18px;
    }

    .section2Titles h2:last-child {
        color: #0f0f43;
        font-size: 40px;
        margin-bottom: 20px;
    }

    .section2Titles h2:last-child span {
        color: #33333393;
    }

    .section3Content {
        margin: 0;
        margin-bottom: 50px;
        padding: 40px 10px;
    }

    .section3Content .right {
        padding: 20px;
    }

    .section3Content .right h6 {
        font-size: 20px;
    }

    .section3Content .right .section3Desc p {
        font-size: 18px;
    }

    .joinTeamContent a {
        justify-content: center;
    }

    .contact {
        justify-content: center;
    }

    .teamBox {
        width: 320px;
    }

    .section5Titles h2 {
        font-size: 45px;
    }
}